import React, { ComponentType, ReactNode } from 'react'
import { Form as FFForm } from 'react-final-form'
import { UiComponent } from 'components/ui/types'

type FormProps = {
  onSubmit: (v: any) => void
  validate?: (v: any) => any
  initialValues?: { [key: string]: any }
  clearOnSubmit?: boolean
  children: ReactNode
} & UiComponent

const Form: ComponentType<FormProps> = ({
  onSubmit,
  validate,
  children,
  initialValues,
  clearOnSubmit = false,
  className = ''
}) => {
  return (
    <FFForm
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitError }) => (
        <form
          onSubmit={(v) => {
            // TODO @Jakub improve
            if (clearOnSubmit) {
              // @ts-ignore
              return handleSubmit(v).then(() => clearOnSubmit && form.reset())
            } else return handleSubmit(v)
          }}
          className={`${className} form`}
        >
          {submitError && <div className="error">{submitError}</div>}
          {children}
        </form>
      )}
    />
  )
}

export default Form
